import TENANT_CONFIG from "../tenant_config";
import { POD_NAMES, DEPLOY_ENV_NAMES } from "../api/constants";

const REACT_APP_TENANT = process.env.REACT_APP_TENANT || "level";

const POD_LIST = Object.values(POD_NAMES);
const DEPLOY_ENV_LIST = Object.values(DEPLOY_ENV_NAMES);

// Checks if item exists in a list via case-insensitive comparison
const caseInsensitiveListIncludes = (list = [], item = "") =>
  list
    .map((lItem) => String(lItem).toUpperCase())
    .includes(String(item).toUpperCase());

// Checks if any of list-items is a substring of item via case-insensitive comparison
const caseInsensitiveStringIncludes = (item = "", list = []) =>
  list.some((lItem) =>
    String(item).toUpperCase().includes(String(lItem).toUpperCase())
  );

export default function getSchemaName(url) {
  if (typeof window === "undefined") {
    return REACT_APP_TENANT;
  }

  let returnValue = "";

  const hostname = String(url || window.location.hostname).replace("-k8", "");
  const [subdomain = ""] = hostname.split(".");
  const [subPlatform = "", subTenant = REACT_APP_TENANT] = subdomain.split("-");

  if (
    caseInsensitiveStringIncludes(hostname, [
      "localhost",
      "dev.dashboards",
      "stagex-dashboard",
      "local.thelevel",
    ])
  ) {
    // NON-URL Based Deployment.
    returnValue = REACT_APP_TENANT;
  } else if (caseInsensitiveListIncludes(POD_LIST, subPlatform)) {
    // platform, pe
    returnValue = subTenant;
  } else if (caseInsensitiveListIncludes(DEPLOY_ENV_LIST, subPlatform)) {
    // dev, staging, stagex, replica, qa
    returnValue = subTenant;
  } else {
    // welp.
    returnValue = subPlatform;
  }

  // 🤞🙏
  return TENANT_CONFIG[returnValue] || returnValue || REACT_APP_TENANT;
}
