const tenantConfig = {
  brex: "andromeda",
  toast: "toast",
  vista: "vistaprint",
  app: "level",
  stagex: "level",
  dashboards: "level",
  qagpt: "vistaprint",
  "3degrees": "threedegrees",
  "3degreessandbox": "threedegreessandbox",
  thefarmersdog: "farmersdog",
  partner: "asuriondemo"
};

export default tenantConfig;
