const DEFAULT_KEY = "WA3Q1k8EMpQHXZ3a7kwo7";

const ENV_KEY_MAP = {
  REPLICA: "MgRjqW2f9SWdSMQ6vUtbM",
  PRODUCTION: "LavqeNxSUVfXMvPP7WPvM",
  STUDIO: "LavqeNxSUVfXMvPP7WPvM",
  STAGING: "W3wktPYryVGwSkG7Gnqr9",
  STAGEX: "W3wktPYryVGwSkG7Gnqr9",
  PE_DEVELOPMENT: "5WNsv9tY3LWWJQ4nPDAf4",
};

export const generateOptimizelyEnvKey = () => {
  const env = process.env.REACT_APP_ENVIRONMENT;

  return ENV_KEY_MAP[env] || DEFAULT_KEY;
};
